<template>
    <b-overlay :show="loading" rounded="sm">
        <b-card no-body class="mb-4 p-4">
            <div class="d-flex justify-content-end mb-2">
                <b-button variant="primary" @click="$bvModal.show('modal-add-obligation')">Add Obligation</b-button>
            </div>

            <div>
                <div class="row border">
                    <div class="col-6 bg-light">
                        <div class="p-2" style="font-weight: bold">Institution</div>
                    </div>
                    <div class="col-6 bg-light">
                        <div class="p-2" style="font-weight: bold; text-align: end">Pending</div>
                    </div>
                </div>
                <div class="row border" v-for="(item, idx) in items" :key="item.id" v-b-toggle="`accordion-${idx}`">
                    <div class="col-6">
                        <div class="p-2">
                            {{ item.bankName }}
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="p-2" style="text-align: end">{{ countRecords(item) }}</div>
                    </div>
                    <b-collapse :id="`accordion-${idx}`" class="w-100">
                        <div class="px-3 pb-2" @click.stop="handleClick">
                            <h6 class="mt-2 ml-2">Customers Screening Records</h6>
                            <b-table
                                style="max-height: 400px; width: 100%"
                                :items="item.customerScreeningRecords"
                                :fields="detailsFields"
                                small
                                responsive
                                head-variant="light"
                                hover
                            >
                                <template v-slot:cell(customerAddress)="data">
                                    {{ data.item.customerAddress }}
                                </template>
                                <template v-slot:cell(submitedToReviewDate)="data">
                                    {{ $formatDate(data.item.submitedToReviewDate) }}
                                </template>

                                <template v-slot:cell(action)="data">
                                    <b-button
                                        @click.stop="showModal(data.item, item)"
                                        variant="primary"
                                        size="sm"
                                        class="mr-2"
                                        >Show Form</b-button
                                    >

                                    <b-button
                                        @click.stop="sendNoLoans(data.item, item)"
                                        variant="outline-danger"
                                        size="sm"
                                        >No Loans</b-button
                                    >

                                    <!-- dropdown button with two options, show form and add obligation -->

                                    <!-- <b-dropdown id="dropdown-1" text="Actions" variant="primary" size="sm">
                                        <b-dropdown-item @click.stop="showModal(data.item)">Show Form</b-dropdown-item>
                                        <b-dropdown-item @click.stop="sendNoLoans(data.item)">
                                            No Loans
                                        </b-dropdown-item>
                                    </b-dropdown> -->
                                </template>
                            </b-table>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </b-card>

        <!-- Modal -->
        <b-modal id="modal-view" centered title="Screening Verification" size="xl" hide-footer>
            <div class="pb-2 d-flex justify-content-between" v-if="customerSelected">
                <h5 class="mail-desc d-block">{{ customerSelected.customerName }}</h5>
                <b-button variant="outline-primary" @click="addLoan">Add Loan</b-button>
            </div>
            <b-form @submit.prevent="saveData">
                <!-- Simple Table -->
                <b-table-simple hover small caption-top responsive>
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th>Balance</b-th>
                            <b-th>Monthly Payment</b-th>
                            <b-th>Obligation Type</b-th>
                            <b-th>Loan Status</b-th>
                            <b-th>Guarantor</b-th>
                            <b-th>Comment</b-th>
                            <b-th></b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-for="(item, idx) in obligations">
                            <b-tr v-if="idx === 1" :key="idx + 't'">
                                <b-td colspan="6"> </b-td>
                            </b-tr>
                            <b-tr :key="idx">
                                <b-td>
                                    <b-form-input
                                        type="number"
                                        placeholder="0.00"
                                        required
                                        v-model="item.amount"
                                        autocomplete="off"
                                        step="0.01"
                                    />
                                </b-td>
                                <b-td>
                                    <b-form-input
                                        type="number"
                                        placeholder="0.00"
                                        required
                                        v-model="item.monthlyPayment"
                                        autocomplete="off"
                                        step="0.01"
                                    />
                                </b-td>
                                <b-td>
                                    <b-form-select v-model="item.obligationType" required>
                                        <option :value="null" disabled>Select obligation type</option>
                                        <option :value="1">Personal</option>
                                        <option :value="2">Credit Card</option>
                                        <option :value="3">Mortgage</option>
                                        <option :value="5">Overdraft</option>
                                        <option :value="6">Vehicle</option>
                                        <option :value="4">Other</option>
                                    </b-form-select>
                                </b-td>
                                <b-td>
                                    <b-form-select
                                        v-model="item.loanStatus"
                                        :required="amount > 0 || monthlyPayment > 0"
                                    >
                                        <option :value="null" disabled>Select loan status</option>
                                        <option value="UTD">UTD</option>
                                        <option value="IN ARREARS">IN ARREARS</option>
                                    </b-form-select>
                                </b-td>
                                <b-td>
                                    <!-- Radio Yes or no -->
                                    <b-form-radio-group
                                        v-model="item.isGuarantor"
                                        required
                                        :options="[
                                            { text: 'Yes', value: true },
                                            { text: 'No', value: false },
                                        ]"
                                    />
                                </b-td>
                                <b-td>
                                    <b-form-input
                                        type="text"
                                        v-model="item.comment"
                                        autocomplete="off"
                                        :required="item.loanStatus === 'IN ARREARS' || item.obligationType == 4"
                                    />
                                </b-td>
                                <b-td>
                                    <b-button
                                        v-if="idx != 0"
                                        variant="outline-danger"
                                        size="sm"
                                        @click="removeObligation(idx)"
                                    >
                                        Remove
                                    </b-button>
                                </b-td>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>

                <!-- <b-form-group label="Amount" label-class="fw-medium mb-1" label-for="amount">
                    <b-form-input
                        id="amount"
                        type="number"
                        placeholder="0.00"
                        required
                        v-model="amount"
                        autocomplete="off"
                        step="0.01"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Monthly Payment" label-class="fw-medium mb-1" label-for="monthly">
                    <b-form-input
                        id="monthly"
                        type="number"
                        placeholder="0.00"
                        required
                        v-model="monthlyPayment"
                        autocomplete="off"
                        step="0.01"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Obligation Type" label-for="obligationtype">
                    <b-form-select v-model="obligationType" id="obligationtype" required>
                        <option :value="null" disabled>Select obligation type</option>
                        <option :value="1">Loan</option>
                        <option :value="2">Credit Card</option>
                        <option :value="3">Mortgage</option>
                        <option :value="4">Other</option>
                    </b-form-select>
                </b-form-group>

                <b-form-group label="Loan Status" label-for="loanStatus">
                    <b-form-select v-model="loanStatus" id="loanStatus" :required="amount > 0 || monthlyPayment > 0">
                        <option :value="null" disabled>Select loan status</option>
                        <option value="UTD">UTD</option>
                        <option value="IN ARREARS">IN ARREARS</option>
                    </b-form-select>
                </b-form-group>

                <b-form-group label="Comment" label-class="fw-medium mb-1" label-for="comment">
                    <b-form-input
                        id="comment"
                        type="text"
                        placeholder="Comment here"
                        v-model="comment"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group> -->

                <div class="d-flex justify-content-end mt-3">
                    <b-button style="min-width: 200px" :disabled="loadingSave" variant="primary" type="submit">
                        <b-spinner small v-if="loadingSave"></b-spinner>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </b-form>
        </b-modal>

        <b-modal id="modal-add-obligation" hide-footer centered title="Financial Obligations" size="lg">
            <FinancialObligations isFromScreening @refreshScreening="getData" />
        </b-modal>
    </b-overlay>
</template>

<script>
import FinancialObligations from '@/components/applications/FinancialObligations.vue';
export default {
    name: 'applications',
    components: { FinancialObligations },

    data: () => ({
        page: {
            title: 'applications',
        },
        fields: [
            { key: 'bankName', label: 'Bank Name' },
            { key: 'pending', label: 'Pending' },
        ],
        detailsFields: [
            { key: 'id', label: '#' },
            { key: 'submitedToReviewDate', label: 'Date Submitted' },
            { key: 'nationalId', label: 'National ID' },
            { key: 'customerName', label: 'Full Name' },
            { key: 'customerAddress', label: 'Address' },
            { key: 'action', label: 'Action' },
        ],

        obligations: [],

        loading: false,
        loadingSave: false,
        amount: null,
        filter: null,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        customerSelected: null,
        bankSelected: null,
        show: false,
        comment: null,
        obligationType: null,
        loanStatus: null,
        monthlyPayment: null,

        items: [],
        totalRows: 0,
    }),
    methods: {
        countRecords(item) {
            return item?.customerScreeningRecords?.length ?? 0;
        },
        handleClick() {},
        async getData() {
            this.loading = true;
            const response = await this.$store.dispatch('customer/screening/get_screeningVerifications');
            this.items = response.dataResult;
            this.totalRows = response.dataRecords;
            this.loading = false;
        },
        setData(item) {
            this.customerSelected = item;
            this.obligations.push({
                id: item.id,
                amount: item.amount,
                monthlyPayment: item.montlyPayment,
                comment: item.comment,
                obligationType: item.obligationType,
                loanStatus: item.loanStatus,
                isGuarantor: item.isGuarantor || false,
            });
        },

        showModal(item, bank) {
            this.obligations = [];
            this.setData(item);
            this.bankSelected = bank;
            this.$bvModal.show('modal-view');
        },

        sendNoLoans(item, bank) {
            this.setData(item);
            this.bankSelected = bank;
            this.saveData();
        },
        addLoan() {
            this.obligations.push({
                amount: null,
                monthlyPayment: null,
                comment: null,
                obligationType: null,
                isGuarantor: false,
                loanStatus: null,
            });
        },
        removeObligation(index) {
            this.obligations.splice(index, 1);
        },

        async saveData() {
            this.loadingSave = true;
            const customerToUpdate = this.obligations[0];
            const newLoans = this.obligations.filter((item, idx) => idx !== 0);

            let data = {
                id: this.customerSelected.id,
                loanApplicationId: this.customerSelected.loanApplicationId,
                guid: this.customerSelected.guid,
                obligationType: customerToUpdate.obligationType,
                loanStatus: customerToUpdate.loanStatus,
                amount: customerToUpdate.amount,
                comment: customerToUpdate.comment,
                isGuarantor: customerToUpdate.isGuarantor,
                montlyPayment: customerToUpdate.monthlyPayment,
            };
            await this.$store.dispatch('customer/screening/update_fromVerificationForm', data);

            for (const item of newLoans) {
                await this.$store.dispatch('customer/screening/add_screeningVerification', {
                    bankId: this.bankSelected.bankId,
                    loanApplicationId: this.customerSelected.loanApplicationId,
                    customerId: this.customerSelected.customerId,
                    amount: item.amount,
                    montlyPayment: item.monthlypayment,
                    obligationType: item.obligationType,
                    comment: item.comment,
                    isGuarantor: item.isGuarantor,
                    formIsCompleted: true,
                    loanStatus: item.loanStatus,
                });
            }

            await this.getData();
            this.obligations = [];
            this.amount = null;
            this.loadingSave = false;
            this.$bvModal.hide('modal-view');
        },
        clearData() {
            this.customerSelected = null;
        },
    },
    computed: {},
    mounted() {},
    created() {
        this.getData();
    },
};
</script>
